import React from "react";

type StylingProps = {
    layout: TLayout;
    className: string;
}

/**
 * <Styling> component:
 * renders <style> tag with typographies and cta buttons
 */
export default class Styling extends React.Component<StylingProps> {
    static typographyNames = [
        "headline1", "headline2", "headline3", "headerText", "headlineGeneralText",
        "navBarText", "navBarItems", "navBarSubItems",
        "generalText", "generalTextBold", "caption",
        "buttonText", "buttonTextThin",
        "toastbarText", "highlighted", "cardTitle",
        "footerText", "footerTitle",
        "searchWidgetText", "searchWidgetAnswerText", "searchWidgetDetail",
    ]

    private get fonts(): Array<{ family: string; location: string, variable: boolean}> {
        const sourcefonts = this.props.layout?.texts_styles?.fonts || [];
        return sourcefonts.map(({item})=>item);
    }

    private get typography(): Record<string, { general: string; desktop: string; }> {
        const source: any = this.props.layout?.texts_styles || {};
        const result: Record<string, { general: string; desktop: string; }> = {};
        for (const typoname of Styling.typographyNames) {
            result[typoname] = {
                general: (source[typoname] || "").replace(/\n/g, " "),
                desktop: (source[typoname+"_desktop"] || "").replace(/\n/g, " "),
            };
        }
        return result;
    }

    private getInlineStyleForClass(typoname: string, general: string, desktop: string) {
        let csstext = general;

        // desktop media query for typo
        const desktopMedia = desktop !== "" ? `@media (min-width: 1280px) { .${this.props.className} .${typoname} { ${desktop} } }` : '';

        // we support safari( inline safari styles )
        const safariOnly = /safari\((.*?)\)/.exec(general);
        let safariMedia = '';
        if (safariOnly) {
            csstext = csstext.replace(safariOnly[0], '');
            safariMedia = `.safari-browser.${this.props.className} .${typoname} { ${safariOnly[1]} }`
        }
        // return css styles for typoclass
        return `.${this.props.className} .${typoname} { ${csstext} } ${desktopMedia} ${safariMedia}`;
    }

    private getCTAButtonsStyle(): string {
        const layout = this.props.layout;

        let ctaBorderRadius = '0px';
        if (layout.cta_type === 'square') ctaBorderRadius = '0px';
        if (layout.cta_type === 'rounded') ctaBorderRadius = '8px';
        if (layout.cta_type === 'full-rounded') ctaBorderRadius = '9999px';

        return `
        .cta-button {
            border-radius: ${ctaBorderRadius};
            background-color: var(--button-background-color, ${layout.cta_background_color?.value});
            color: var(--button-text-color, ${layout.cta_text_color?.value});
            padding: 10px 16px;
            min-height: 44px;
            height: auto;
            display: flex;
            align-items: center;
            cursor: pointer;
            text-align: center;
        }
        .cta-button:hover {
            background-color: var(--button-hover-background-color, ${layout.cta_hover_background_color?.value || layout.cta_background_color?.value || 'unset'});
            color: var(--button-hover-text-color, ${layout.cta_hover_text_color?.value || layout.cta_text_color?.value || 'unset'});
        }
        .cta-secondary-button {
            border-radius: ${ctaBorderRadius};
            background-color: var(--button-background-color, ${layout.secondary_cta_background_color?.value});
            color: var(--button-text-color, ${layout.secondary_cta_text_color?.value});
            border: 1px solid var(--button-border-color, ${layout.secondary_cta_line_color?.value || 'unset'});
            padding: 10px 16px;
            min-height: 44px;
            height: auto;
            display: flex;
            align-items: center;
            cursor: pointer;
            text-align: center;
        }
        .cta-secondary-button:hover {
            background-color: var(--button-hover-background-color, var(--button-background-color, ${layout.secondary_cta_hover_background_color?.value || layout.secondary_cta_background_color?.value}));
            border-color: var(--button-border-hover-color, var(--button-border-color, ${layout.secondary_cta_hover_line_color?.value || layout.secondary_cta_line_color?.value || 'unset'}));
            color: var(--button-hover-text-color, ${layout.secondary_cta_hover_text_color?.value || layout.secondary_cta_text_color?.value || 'unset'});
        }
        .cta-soft-button {
            color: var(--button-text-color, ${layout.soft_button_color?.value});
            height: 44px;
            display: flex;
            align-items: center;
            cursor: pointer;
        }
        .cta-soft-button:hover {
            color: var(--button-hover-text-color, ${layout.soft_button_hover_color?.value || layout.soft_button_color?.value || 'unset'});
        }

        .cta-button:disabled,
        .cta-secondary-button:disabled,
        .cta-soft-button:disabled {
            cursor: default;
            background-color: #f0f0f0;
            border-color: #f0f0f0;
            color: #ffffff;
        }
        :root { --ctaBorderRadius: ${ctaBorderRadius}; }
        .def-cta-button { border-radius: ${ctaBorderRadius}; }
        `.replace(/\s+/g, " ")
    }

    private getCommonLinksStyle(): string {
        const layout = this.props.layout;
        return `
        .html a:not(.cta-button):not(.cta-secondary-button):not(.cta-soft-button),
        .module-head p a {
            color: var(--hyperlink-text-color, ${layout.hyperlink_text_color?.value});
            text-decoration: underline;
        }
        .html a:not(.cta-button):not(.cta-secondary-button):not(.cta-soft-button):hover,
        .module-head p a:hover {
            color: var(--hyperlink-hover-color, ${layout.hyperlink_hover_color?.value});
            text-decoration: underline;
        }
        `.replace(/\s+/g, " ");
    }

    private getFontFaceDefinition(family: string, location: string, variable: boolean = false, index: number): string {
        const fontDisplay = index === 0 ? 'block' : 'swap';
        if (variable) return `@font-face { font-family: '${family}'; src: url("/fonts/${location}.woff2") format('woff2-variations'); font-display: ${fontDisplay}; }`;
        return `@font-face { font-family: '${family}'; src: url("/fonts/${location}.woff2") format('woff2'), url("/fonts/${location}.woff") format('woff'); font-display: ${fontDisplay}; }`;
    }

    private get stylingContents() {
        const fonts = this.fonts;
        const typos = this.typography;
        return `
        ${fonts.map((font, index) => this.getFontFaceDefinition(font.family, font.location, font.variable, index)).join("\n")}
        ${Styling.typographyNames.map(typoname => this.getInlineStyleForClass(typoname, typos[typoname].general, typos[typoname].desktop)).join("\n")}
        ${this.getInlineStyleForClass('generalText2', typos['generalText'].general, typos['generalText'].desktop)}
        ${this.getInlineStyleForClass('generalText2Bold', typos['generalTextBold'].general, typos['generalTextBold'].desktop)}
        ${this.getCTAButtonsStyle()}
        ${this.getCommonLinksStyle()}
        ${this.props.layout?.texts_styles?.other_styles || ""}
        `.replace(/\s+/g, " ");
    }

    /** renders <style> tag with typographies and cta buttons */
    render() {
        const csscontents = this.stylingContents;
        return <style id="typostyles" data-lid={this.props.layout?.id} dangerouslySetInnerHTML={{__html: csscontents}} />
    }
}